// ******************************
// Import scss files you need!!!
// ******************************

@import "../../../../styles/base";
@import "../../../../styles/config";

// ******************************
// end of the imported scss files
// ******************************

.container{
	display: flex;
	align-items: center;
	flex: auto;
	flex-direction: column;
	height: 100%;
	width: 100%;
	color: $txt-color;

	.header{
		display: flex;
		align-items: center;
		justify-content: flex-end;
		flex-direction: column;
		position: relative;
		color: $title-color;
		padding: $offset-l $offset-xl;
		overflow: hidden;
		font-size: $header;
		font-weight: $font-bold;
		text-align: center;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 100%;
		height: 300px;

		.bg {
			position: absolute;
			top: 0;
			background-image: url('../../media/img/command-center.jpg');
			background-position: center;
			background-repeat: no-repeat;
			width: 100%;
			height: 180px;
			opacity: .9;
		}
	}
	.title {
		margin: $offset-l 0;
	}
	.row {
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: space-between;

		.btn {
			min-width: 200px;
		}
	}
	.btn {
		margin-bottom: $offset-l;
		margin-top: $offset-l;
	}
	.body{
		display: flex;
		flex-direction: column;
		flex: auto;
		width: 100%;
		padding: $offset-xl;

		.tabContainer{
			width: 100%;

			.extraRow {
				display: flex;
				align-items: center;

				.label {
					margin-right: $offset-m;
				}
				.select {
					min-width: 200px;
				}
			}

			.panel {
				margin-bottom: $offset-l;

				.gridRow {
					display: grid;
					grid-auto-flow: dense;
					grid-template-columns: 1fr 1fr 1fr;
					grid-gap: 10px;
					justify-content: space-around;
					align-content: space-evenly;
				}
				.order {
					&.order-1 {
						grid-column: 1;
					}
					&.order-2 {
						grid-column: 2;
					}
					&.order-3 {
						grid-column: 3;
					}
					&.order-4 {
						grid-column: 4;
					}
					&.order-5 {
						grid-column: 5;
					}
					&.order-6 {
						grid-column: 6;
					}
					&.order-7 {
						grid-column: 7;
					}
					&.order-8 {
						grid-column: 8;
					}
					&.order-9 {
						grid-column: 9;
					}
					&.order-10 {
						grid-column: 10;
					}
				}
				.orderRoetb {
					&.order-1 {
						grid-column: 3;
					}
					&.order-2 {
						grid-column: 1;
					}
					&.order-3 {
						grid-column: 2;
					}
				}
			}
			.contentRow {
				display: flex;
				align-items: center;
				flex: auto;
				margin-bottom: $offset-l;

				.label {
					width: 100px;
				}
				.select {
					width: 100%;
					max-width: 500px;
					margin-left: $offset-l;

					&.form-item {
						margin-bottom: 0;

						:global(.ant-input-group-addon) {
							padding: 0;
						}
						.btnSave {
							padding: 0;
							height: 30px;
							width: 30px;
						}
						.btnArrow {
							padding: 0 $offset-l;
						}
					}
				}
			}
		}
	}
	.icon {
		margin-right: $offset-m;
	}
}
.dropMenu {
	max-height: 500px;
	overflow: auto;

	.rowMenu {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex: auto;
	}
}

